import './personas.css'

import Simon from './persona/simon/simon.jsx'
import Peter from './persona/peter/peter.jsx'
import Sarah from './persona/sarah/sarah.jsx'

function Personas() {
    return (
        <div className="personas">
            <Simon />
            <Peter />
            <Sarah />
        </div>
    );
}

export default Personas;