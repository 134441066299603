import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import { useState } from 'react';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import Launchpage from "./Page/Launchpage";
const Gamepage = React.lazy(()=>import("./Page/GamePage"))
const Endpage = React.lazy(()=>import("./Page/endpage"))
const CompletedList = React.lazy(()=>import("./Page/completedList"))

function App() {

    const [SpielerDaten,setSpielerdaten] = useState([])
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"game"} element={<Suspense fallback={<h1>Loading... </h1>}><Gamepage setSpielerdaten={setSpielerdaten}/></Suspense>}/>
                <Route path={"endpage"} element={<Suspense fallback={<h1>Loading... </h1>}><Endpage Spielerdaten ={SpielerDaten}/></Suspense>}/>
                <Route path={"list"} element={<Suspense fallback={<h1>Loading... </h1>}><CompletedList/></Suspense>}/>
                <Route index element={<Launchpage/>}/>
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('App'));
root.render(
        <App />
);
