import './popupCreator.css'

function PopupCreator(props) {
    if (props.visibility) {
        return (
            <div>

                <div id="popupCreatorHintergrund"></div>

                <div className="popupCreator">

                    <button onClick={() => props.toggleVisibility(6)}>X</button>

                    <img src={require("../dataCreator/"+props.photo)} alt="Profile" />

                    <div>
                        <div className="popupText">
                            <p className="valueName">Name:</p>
                            <p className="value">{props.name}</p>
                        </div>

                        <div className="popupText">
                            <p className="valueName">Role:</p>
                            <p className="value">{props.role}</p>
                        </div>

                        <div className="popupText">
                            <p className="valueName">Profession:</p>
                            <p className="value">{props.profession}</p>
                        </div>

                        <div className="popupText" id="popupTextEnde">
                            <p className="valueName">Major:</p>
                            <p className="value">{props.major}</p>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

PopupCreator.defaultProps = {
    visibility: true,
    name: '',
    role: '',
    profession: '',
    major: ''
}

export default PopupCreator;