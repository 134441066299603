import './header.css'

function Header() {
    return (
        <div className="header">
            <p id="spielName">Hippo-Codes</p>
            <div>
                <div>
                    <img src={require('./hippo.png')} alt="Hippo-Codes Logo"></img>
                </div>
            </div>
            <p id="unternehmenName">Thermopolis</p>
        </div>
    );
}

export default Header;