import './footer.css'

import React from 'react'
import PopupCreator from './popupCreator/popupCreator';
import Popup from 'reactjs-popup';
import LaunchQuellenPopUp from './launchQuellenPopUp/launchQuellenPopUp';

class Footer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        visibility: false
      };
      this.toggleVisibility = this.toggleVisibility.bind(this);
    }
    toggleVisibility(id) {
        if (!(id === 6)) {
            var data = require('./dataCreator/dataCreator.json')
            this.setState({
                photo: data[id].photo,
                name: data[id].name,
                role: data[id].role,
                profession: data[id].profession,
                major: data[id].major
            });
        }
        this.setState(state => ({
            visibility: !state.visibility
        }));
    }

   
    render() {
        if (this.state.visibility) {
            return (
                <div className="footer">
                    <Popup trigger={<button className='FooterButton'>Kontakt</button>} position="top left" >
                    <div className='kontaktPopUp'><p>Schicke uns gerne Feedback an feedback@hippo-codes.com. Falls du Fragen zum Spiel haben solltest stehen wir dir gerne unter help@hippo-codes.com zur Verfügung</p></div>
                    </Popup>
                    <p>John-Thomas Ebert</p>
                    <p>Laura Krone</p>
                    <p>Leonard Eckert</p>
                    <p>Nico Wagner</p>
                    <p>Niklas Denz</p>
                    <p>Tobias Schnarr</p>
                    <Popup trigger={<button className='FooterButton'>Unsere Quellen</button>} position="top right" >
                    <LaunchQuellenPopUp /> 
                    </Popup>
                    <PopupCreator toggleVisibility={this.toggleVisibility} visibility={this.state.visibility} name={this.state.name} role={this.state.role} profession={this.state.profession} major={this.state.major} photo={this.state.photo}/>
                </div>
            );
        } else {
            return (
                <div className="footer">
                    <Popup trigger={<button className='FooterButton'>Kontakt</button>} position="top left" >
                    <div id="popupCreatorHintergrund"></div>
                    <div className='kontaktPopUp'><p>Schicke uns gerne Feedback an feedback@hippo-codes.com. Falls du Fragen zum Spiel haben solltest stehen wir dir gerne unter help@hippo-codes.com zur Verfügung</p></div>
                    </Popup>
                    <p onClick={() => this.toggleVisibility(0)}>John-Thomas Ebert</p>
                    <p onClick={() => this.toggleVisibility(1)}>Laura Krone</p>
                    <p onClick={() => this.toggleVisibility(2)}>Leonard Eckert</p>
                    <p onClick={() => this.toggleVisibility(3)}>Nico Wagner</p>
                    <p onClick={() => this.toggleVisibility(4)}>Niklas Denz</p>
                    <p onClick={() => this.toggleVisibility(5)}>Tobias Schnarr</p>
                    <Popup trigger={<button className='FooterButton'>Unsere Quellen</button>} position="top right" >
                    <LaunchQuellenPopUp /> 
                    </Popup>
                </div>
            );
        }
    }
};

export default Footer;