import './keypoints.css'

function Keypoints() {
    return (
        <div className="keypoints">
            <div>
                <img src={require('./iconValid.png')} alt="Icon Valid"></img>
                <p>Valid</p>
            </div>

            <div>
                <img src={require('./iconLearn.png')} alt="Icon Learn"></img>
                <p>Learn</p>
            </div>

            <div>
                <img src={require('./iconFun.png')} alt="Icon Fun"></img>
                <p>Fun</p>
            </div>
            
            <div>
                <div>
                    <img src={require('./iconCertified.png')} alt="Icon Certified" id="iconCertified"></img>
                </div>
                <p>Confirm</p>
            </div>
        </div>
    );
}

export default Keypoints;