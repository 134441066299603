import React from 'react';

import './launchpage.css'
import Header from '../components_Launchpage/header/header.jsx';
import Overview from '../components_Launchpage/overview/overview.jsx';
import Keypoints from '../components_Launchpage/keypoints/keypoints.jsx';
import Personas from '../components_Launchpage/personas/personas.jsx';
import Footer from '../components_Launchpage/footer/footer.jsx';

export default function Launchpage() {
  return (
    <div className="landingpage">
      <Header /> 
      <Overview />
      <Keypoints />
      <Personas />
      <Footer />
    </div>
  );
}


