import './launchQuellenPopUp.css'

function LaunchQuellenPopUp() {

    var data = require("../Quellen.json")
    const InhaltLiItems = []
    const BilderLiItems = []
    const SonstLiItems = []

    for(let i=0; i<data["Inhalt"].length;i++){
        for (const [key, value] of Object.entries(data["Inhalt"][i])) {
            InhaltLiItems.push(<li className='listItems'><a href = {value} target="_blank" rel="noreferrer">{key}</a></li>)
          }

    }
    for(let i=0; i<data["Icons"].length;i++){
        for (const [key, value] of Object.entries(data["Icons"][i])) {
            BilderLiItems.push(<li className='listItems'><a href = {value} target="_blank" rel="noreferrer">{key}</a></li>)
          }

    }
    for(let i=0; i<data["Karte"].length;i++){
        for (const [key, value] of Object.entries(data["Karte"][i])) {
            BilderLiItems.push(<li className='listItems'><a href = {value} target="_blank" rel="noreferrer">{key}</a></li>)
          }

    }
    for(let i=0; i<data["Trailer"].length;i++){
        for (const [key, value] of Object.entries(data["Trailer"][i])) {
            SonstLiItems.push(<li className='listItems'><a href = {value} target="_blank" rel="noreferrer">{key}</a></li>)
          }

    }
   

  

    console.log(InhaltLiItems)

    return (
        <div className="launchQuellenPopUp">
            <div className="launchQuellenBackground">
                <h1> Unsere Quellen</h1>

                <table width={"100%"}>
                    <tr>
                        <th width="50%">Inhaltsquellen </th>
                        <th width="30%">Grafikquellen</th>
                        <th width="20%">Trailer Quellen</th>
                    </tr>
                    <tr>
                        <td>
                            <ul className='quellen'>
                                {InhaltLiItems}
                            </ul>
                        </td>
                        <td>
                            <ul className='quellen'>
                                {BilderLiItems}
                            </ul>
                        </td>
                        <td>
                            <ul className='quellen'>
                                {SonstLiItems}
                            </ul>
                        </td>
                    </tr>
                   
                </table>
               
            </div>
        </div>
    );
}

export default LaunchQuellenPopUp;