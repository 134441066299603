function Simon() {
    return (
        <div>
            <div className="personaBild">
                <img src={require('./simon.jpg')} alt="Simon" />
            </div>
            <div className="personaBeschreibung">
                <p className="personaName">Simon (student)</p>
                <p className="personaText">
                    "Thermopolis is a game-changer, seamlessly blending education and fun, 
                    making learning an exciting journey for students of all ages."
                </p>
            </div>
        </div>
    );
}

export default Simon;