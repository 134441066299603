function Sarah() {
    return (
        <div>
            <div className="personaBild">
                <img src={require('./sarah.jpg')} alt="Sarah" />
            </div>
            <div className="personaBeschreibung">
                <p className="personaName">Sarah (scientist)</p>
                <p className="personaText">
                    "As a scientist, I'm thoroughly impressed with Thermopolis robust and thoroughly-researched 
                    content, enhancing the educational journey significantly."
                </p>
            </div>
        </div>
    );
}

export default Sarah;