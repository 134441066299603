function Peter() {
    return (
        <div>
            <div className="personaBild">
                <img src={require('./peter.jpg')} alt="Peter" />
            </div>
            <div className="personaBeschreibung">
                <p className="personaName">Peter (professor)</p>
                <p className="personaText">
                    "From a professor's perspective, Thermopolis  is a goldmine of well-researched 
                    content, enriching the learning experience remarkably."
                </p>
            </div>
        </div>
    );
}

export default Peter;