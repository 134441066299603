import './overview.css'
import {Link} from "react-router-dom";
import YouTube, { YouTubeProps } from 'react-youtube';


export default function Overview() {
    const videoOptions = {
        playerVars: {
          autoplay: 1,
          controls: 0,
          rel: 0,
          showinfo: 0,
          mute: 0,
          loop: 1
        }
    };

    const onReady = (event) => {
        event.target.playVideo();
    }

    return (
        <div className="overview">
            <div>
                <div>
                    <p>Play. Learn.</p>
                    <p>Impact.</p>
                </div>
                <Link to={"game"}>
                    <button>Start Game</button>
                </Link>

            </div>
            <div id="video">
                <YouTube id="trailer" videoId="fc6xv7Whb9g?si=GrXgTBBTyrg_Azvi" opts={videoOptions} onReady={(e) => onReady(e)}/>
            </div>
        </div>
    );
};